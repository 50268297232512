import React from 'react'
import styled from 'styled-components'

// Breadcrumbs
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// Media
import chevron from 'img/chevron-breadcrumb.svg'

interface BreadcrumbsProps {
  pageContext: any
  location: any
}

const StyledBreadcrumbs = styled.div`
  & .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;
  }

  & ol {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;

    & li {
      list-style: none;

      & a {
        color: ${({ theme }) => theme.color.light};
        font-size: ${({ theme }) => theme.font.size.small};
        opacity: 0.5;
        transition: all 0.3s ease;

        &:hover {
          text-decoration: underline;
          opacity: 1;
        }

        &[aria-current] {
          opacity: 1;
        }
      }
    }

    & span {
      margin: 0 0.75rem;
      color: ${({ theme }) => theme.color.light};
      font-size: 0;
      position: relative;
      top: 1px;

      &:after {
        content: url(${chevron});
        opacity: 0.5;
      }
    }
  }
`

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ pageContext }) => {
  if (!pageContext || !pageContext.breadcrumb) {
    return null
  }

  const {
    breadcrumb: { crumbs },
  } = pageContext

  crumbs.forEach((c: any) => {
    const uppercased = c.crumbLabel[0].toUpperCase() + c.crumbLabel.substring(1)

    // eslint-disable-next-line no-param-reassign
    c.crumbLabel = uppercased.replace('-', ' ')
  })

  return (
    <StyledBreadcrumbs>
      <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
    </StyledBreadcrumbs>
  )
}

export default Breadcrumbs
