/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const BannerWrapper = styled.div`
  position: relative;

  @media (min-width: 992px) {
    height: calc(100vh - 80px);
  }

  @media (max-width: 991px) {
    height: calc(80vh - 70px);
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const BannerWrapperBackground = styled.div`
  @media (max-width: 992px) {
    background-color: #000000;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.25;
    z-index: 1;
  }
`

const BannerWrapperContent = styled.div`
  position: relative;
  z-index: 2;
`

const BannerContent = styled.div`
  background-color: rgba(28, 28, 28, 0.75);
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
  position: relative;

  @media (min-width: 992px) {
    margin-bottom: 10rem !important;
  }

  & p:not(a) {
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.color.light};

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }
  }

  & h1 {
    font-size: ${({ theme }) => theme.font.size.ultra};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.light};

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.huge};
    }
  }

  & h3 {
    color: ${({ theme }) => theme.color.primary};
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.font.size.large};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    margin-bottom: 1rem;
  }
`

const BannerButton = styled.button`
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: 37px;
  height: 37px;
  margin-left: -25px;
  z-index: 2;

  & svg {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }
`

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => {
  const ref = React.useRef<HTMLElement>(null)

  return (
    <section ref={ref}>
      <BannerWrapper>
        <StyledPlaatjie alt="banner" image={fields?.image} />
        <BannerWrapperBackground />
        <BannerWrapperContent className="container py-5 h-100">
          <div className="row h-100 d-flex align-items-center align-items-lg-end pb-5 px-3 px-lg-0">
            <BannerContent className="col-12 col-sm-10 col-lg-5 p-4 p-lg-5">
              <ParseContent content={fields.description || ''} />
              {fields?.link && (
                <button
                  role="button"
                  className="btn-secondary mt-3"
                  onClick={() => {
                    if (!ref.current) {
                      return
                    }

                    window.scrollBy(
                      0,
                      ref.current?.clientHeight - window.scrollY
                    )
                  }}
                >
                  {fields?.link?.title}
                </button>
              )}
            </BannerContent>
          </div>
        </BannerWrapperContent>
        <BannerButton
          type="button"
          onClick={() => {
            if (!ref.current) {
              return
            }

            window.scrollBy(0, ref.current?.clientHeight - window.scrollY)
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
          >
            <g clipPath="url(#clip0_615_745)" filter="url(#filter0_d_615_745)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.1602 20.7656C30.4492 20.4766 30.5937 20.043 30.5937 19.6094C30.5937 18.5977 29.8711 17.875 28.8594 17.875C28.2813 17.875 27.8477 18.1641 27.5586 18.4531L22.5 23.9453L17.4414 18.4531C17.1523 18.1641 16.7187 17.875 16.1406 17.875C15.1289 17.875 14.4062 18.5977 14.4062 19.6094C14.4062 20.043 14.5508 20.4766 14.8398 20.7656L21.1992 27.7031C21.4883 27.9922 21.9219 28.2812 22.5 28.2812C23.0781 28.2812 23.5117 28.1367 23.8008 27.7031L30.1602 20.7656ZM41 22.5C41 12.2383 32.7617 4 22.5 4C12.2383 4 4 12.2383 4 22.5C4 32.7617 12.2383 41 22.5 41C32.7617 41 41 32.7617 41 22.5ZM7.46875 22.5C7.46875 14.2617 14.2617 7.46875 22.5 7.46875C30.7383 7.46875 37.5312 14.2617 37.5312 22.5C37.5312 30.7383 30.7383 37.5312 22.5 37.5312C14.2617 37.5312 7.46875 30.7383 7.46875 22.5Z"
                fill="white"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_615_745"
                x="0"
                y="0"
                width="45"
                height="45"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_615_745"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_615_745"
                  result="shape"
                />
              </filter>
              <clipPath id="clip0_615_745">
                <rect
                  width="37"
                  height="37"
                  fill="white"
                  transform="translate(41 4) rotate(90)"
                />
              </clipPath>
            </defs>
          </svg>
        </BannerButton>
      </BannerWrapper>
    </section>
  )
}

export default BannerHome
