import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import Breadcrumbs from 'components/elements/Breadcrumbs'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
  pageContext?: any
}

const StyledBannerDefault = styled.section``

const BannerWrapper = styled.div`
  position: relative;

  @media (min-width: 992px) {
    height: 600px;
  }

  @media (max-width: 991px) {
    height: 400px;
  }

  @media (max-width: 767px) {
    height: 300px;
  }
`

const BannerPlaatjie = styled.div`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;
  max-width: 2000px;
  margin: 0 auto;
  display: block;
`

const BannerContent = styled.div`
  position: relative;
  z-index: 2;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
`

const BannerContentBlock = styled.div`
  background-color: rgba(28, 28, 28, 0.75);
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
  position: relative;
`

const BannerDefault: React.FC<BannerDefaultProps> = ({
  fields,
  location = {},
  pageContext = {},
}) => (
  <StyledBannerDefault>
    <BannerWrapper>
      <BannerPlaatjie>
        <StyledPlaatjie alt="banner" image={fields?.image} />
      </BannerPlaatjie>
      <BannerContent className="container h-100">
        <div className="row h-100">
          <div className="col-12 col-sm-10 col-md-5 h-100 d-flex align-items-center">
            <BannerContentBlock className="p-4 p-lg-5 w-100">
              <div className="mb-3 mb-lg-4">
                <Breadcrumbs location={location} pageContext={pageContext} />
              </div>
              <Content content={fields.description || ''} />
            </BannerContentBlock>
          </div>
        </div>
      </BannerContent>
    </BannerWrapper>
  </StyledBannerDefault>
)

export default BannerDefault
