import React from 'react'

// Components
import BannerDefault from 'components/flex/Banner/BannerDefault'
import BannerHome from 'components/flex/Banner/BannerHome'

interface BannerShellProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
  pageContext?: any
}

interface BannersProps {
  [key: string]: any
}

const BannerShell: React.FC<BannerShellProps> = ({
  fields,
  location = {},
  pageContext = {},
}) => {
  const banners: BannersProps = {
    default: BannerDefault,
    home: BannerHome,
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default BannerShell
